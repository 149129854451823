import {GoogleApiWrapper, Map, Marker} from "google-maps-react";
import React,{Component} from "react";

export class MapContainer extends Component{
    render() {
        return (
            <div style={{position: "relative", height: 200}}>
                <Map google={this.props.google} zoom={16} style={{width: "100%", height: 200}}
                     initialCenter={{lat: -7.303699, lng: 109.362541}} disableDefaultUI={true}>
                    <Marker position={{lat: -7.303699, lng: 109.362541}} onClick={()=>{}}
                            title="Lokasi Kami" name="Teknoku Digital Indonesia PT."
                            icon={{
                                url: "https://cdn.teknoku.digital/images/logo-circle.png",
                                anchor: new window.google.maps.Point(16,16),
                                scaledSize: new window.google.maps.Size(32,32)
                            }}/>
                </Map>
            </div>
        );
    }

}

export default GoogleApiWrapper({
    apiKey: "AIzaSyAyPpsFNUmog48E3Ogdcgy_C-tlZyTTz_8"
})(MapContainer);
