import React, {Component} from "react";
import dispatcher from "../dispatcher";
import '../styles/_loader.scss';

export default class Loader extends Component{
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
    }

    componentDidMount() {
        dispatcher.register(this.handleAction.bind(this));
    }

    handleAction(action){
        if(action.type === "LOADER"){
            if(action.show){
                this.setState({
                    show: true
                });
            }else{
                this.setState({
                    show: false
                });
            }
        }
    }

    render() {
        return (
            <div className={`loader ${this.state.show?"":"hide"} d-flex justify-content-center align-items-center flex-column`}>
                <img className="logo" src={`${process.env.PUBLIC_URL}/assets/img/logo.png`} alt="logo" />
                <div className="lds-ellipsis">
                    <div/>
                    <div/>
                    <div/>
                    <div/>
                </div>
            </div>
        );
    }
}

export function show() {
    dispatcher.dispatch({
        type: "LOADER",
        show: true
    });
}

export function hide() {
    dispatcher.dispatch({
        type: "LOADER",
        show: false
    });
}
