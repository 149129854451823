import React, {Component} from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Linker from "./Linker";

export default class Layout extends Component{
    render() {
        return (
            <div>
                <Navbar activeIndex={this.props.activeIndex} />
                <div className="content container container-fluid">
                    {this.props.children}
                    <Linker />
                    <Footer />
                </div>
            </div>
        )
    }
}
