import React, {Component} from "react";
import "../styles/_pagination.scss";

/**
 * @param ({page: number, totalPage: number, perPage: number, perPageOptions: Array, perPageChanged: Function})
 */
export default class Pagination extends Component{
    constructor(props) {
        super(props);
        this.state = {
            page: props.page,
            lastPage: props.lastPage,
            perPage: props.perPage,
            perPageOptions: props.perPageOptions,
            perPageChanged: props.perPageChanged,
            pageChanged: props.pageChanged,
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.page !== nextProps.page){
            this.setState({
                page: nextProps.page,
                lastPage: nextProps.lastPage,
                perPage: nextProps.perPage,
            });
        }
        return true;
    }

    render() {
        return (
            <div className="page-container d-flex justify-content-between align-items-end">
                <PerPage perPage={this.state.perPage} perPageOptions={this.state.perPageOptions} perPageChanged={this.state.perPageChanged} />
                <Page pageChanged={this.state.pageChanged} currentPage={this.state.page} lastPage={this.state.lastPage} />
                <PageInput pageChanged={this.state.pageChanged} inputPage={this.state.page} />
            </div>
        );
    }
}

const perPageOptions = [
    {value: 10, label: "10"},
    {value: 20, label: "20"},
    {value: 50, label: "50"},
    {value: 100, label: "100"},
];

class PerPage extends Component{
    constructor(props) {
        super(props);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.state = {
            perPage: props.perPage,
            perPageOptions: props.perPageOptions?props.perPageOptions:perPageOptions,
            perPageChanged: props.perPageChanged,
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.perPage !== nextProps.perPage){
            this.setState({
                perPage: nextProps.perPage,
            });
        }
        return true;
    }

    render() {
        return (
            <div className="form-group page-per-page">
                <label>Per Page</label>
                <select className="form-control" name="per_page" onBlur={e => {
                    this.state.perPageChanged(e.target.value);
                }} onKeyUp={this.onKeyUp} defaultValue={this.state.perPage}>
                    {this.state.perPageOptions.map(({value, label}, index) =>
                        <option key={value} value={value}>{ label }</option>
                    )}
                </select>
            </div>
        );
    }

    onKeyUp(e){
        switch (e.key){
            case "Enter": if(this.state.perPageChanged!==null) this.state.perPageChanged(this.state.perPage); break;
            default:
        }
    }
}

class Page extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: props.currentPage,
            lastPage: props.lastPage,
            pageChanged: props.pageChanged,
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.currentPage !== nextProps.currentPage){
            this.setState({
                currentPage: nextProps.currentPage,
                lastPage: nextProps.lastPage,
            });
        }
        return true;
    }

    render() {
        return (
            <ul className="pagination justify-content-center align-items-center">
                {
                    this.state.currentPage > 1 &&
                    <li className="page-item">
                        <a className="page-link" href="#" aria-label="Previous"
                           onClick={e => {e.preventDefault();this.state.pageChanged(this.state.currentPage-1);}}>
                            <span aria-hidden="true">&laquo;</span>
                            <span className="sr-only">Previous</span>
                        </a>
                    </li>
                }

                {
                    this.state.currentPage>1 &&
                    <li className="page-item"><a className="page-link" href="#" onClick={e => {e.preventDefault();this.state.pageChanged(1);}}>1</a></li>
                }
                {
                    this.state.currentPage>2 &&
                    <li className="page-item"><a className="page-link" href="#" onClick={e => {e.preventDefault();this.state.pageChanged(2);}}>2</a></li>
                }
                {
                    this.state.currentPage===7 &&
                    <li className="page-item"><a className="page-link" href="#" onClick={e => {e.preventDefault();this.state.pageChanged(3);}}>3</a></li>
                }

                {
                    this.state.currentPage>7 &&
                    <li className="page-item disabled"><a className="page-link" href="#">...</a></li>
                }

                {
                    this.state.currentPage>5 &&
                    <li className="page-item" onClick={e => {e.preventDefault();this.state.pageChanged(this.state.currentPage-3);}}><a className="page-link" href="#">{this.state.currentPage-3}</a></li>
                }
                {
                    this.state.currentPage>4 &&
                    <li className="page-item" onClick={e => {e.preventDefault();this.state.pageChanged(this.state.currentPage-2);}}><a className="page-link" href="#">{this.state.currentPage-2}</a></li>
                }
                {
                    this.state.currentPage>3 &&
                    <li className="page-item" onClick={e => {e.preventDefault();this.state.pageChanged(this.state.currentPage-1);}}><a className="page-link" href="#">{this.state.currentPage-1}</a></li>
                }

                <li className="page-item active"><a className="page-link" href="#">{ this.state.currentPage }</a></li>

                {
                    this.state.currentPage<this.state.lastPage-2 &&
                    <li className="page-item" onClick={e => {e.preventDefault();this.state.pageChanged(this.state.currentPage+1);}}><a className="page-link" href="#">{this.state.currentPage+1}</a></li>
                }
                {
                    this.state.currentPage<this.state.lastPage-3 &&
                    <li className="page-item" onClick={e => {e.preventDefault();this.state.pageChanged(this.state.currentPage+2);}}><a className="page-link" href="#">{this.state.currentPage+2}</a></li>
                }
                {
                    this.state.currentPage<this.state.lastPage-4 &&
                    <li className="page-item" onClick={e => {e.preventDefault();this.state.pageChanged(this.state.currentPage+3);}}><a className="page-link" href="#">{this.state.currentPage+3}</a></li>
                }

                {
                    this.state.currentPage<this.state.lastPage-6 &&
                    <li className="page-item disabled"><a className="page-link" href="#">...</a></li>
                }

                {
                    this.state.currentPage===this.state.lastPage-6 &&
                    <li className="page-item" onClick={e => {e.preventDefault();this.state.pageChanged(this.state.lastPage-2);}}><a className="page-link" href="#">{this.state.lastPage-2}</a></li>
                }
                {
                    this.state.currentPage<this.state.lastPage-1 &&
                    <li className="page-item" onClick={e => {e.preventDefault();this.state.pageChanged(this.state.lastPage-1);}}><a className="page-link" href="#">{this.state.lastPage-1}</a></li>
                }
                {
                    this.state.currentPage<this.state.lastPage &&
                    <li className="page-item" onClick={e => {e.preventDefault();this.state.pageChanged(this.state.lastPage);}}><a className="page-link" href="#">{this.state.lastPage}</a></li>
                }

                {
                    this.state.currentPage<this.state.lastPage &&
                    <li className="page-item" onClick={e => {e.preventDefault();this.state.pageChanged(this.state.currentPage+1);}}>
                        <a className="page-link" href="#" aria-label="Previous">
                            <span aria-hidden="true">&raquo;</span>
                            <span className="sr-only">Next</span>
                        </a>
                    </li>
                }
            </ul>
        );
    }

}

export class PageInput extends Component {
    constructor(props) {
        super(props);
        this.onKeyUp = this.onKeyUp.bind(this);
        this.state = {
            pageChanged: props.pageChanged,
            inputPage: props.inputPage,
        };
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if(this.props.inputPage !== nextProps.inputPage){
            this.setState({
                inputPage: nextProps.inputPage,
            });
        }
        return true;
    }

    render() {
        return (
            <div className="form-group page-input">
                <label>Page</label>
                <input className="form-control" name="page" onChange={e => this.setState({inputPage : e.target.value})} onBlur={()=>this.state.pageChanged(this.state.inputPage)} onKeyUp={this.onKeyUp} value={this.state.inputPage} />
            </div>
        );
    }

    onKeyUp(e){
        switch (e.key){
            case "Enter": if(this.state.pageChanged!==null) this.state.pageChanged(this.state.inputPage); break;
            default:
        }
    }
}
