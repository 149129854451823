import React, {Component} from "react";
import '../styles/_apply.scss';
import * as moment from "moment";
import Moment from "react-moment";
import parse from "html-react-parser";
import {axiosInstance, GOOGLE_RECAPTCHA_SITE_KEY} from "../App";
import {ReCAPTCHA} from "react-google-recaptcha";

export default class Apply extends Component{
    constructor(props) {
        super(props);
        this.state = {
            data: {},
            applicant: {},
            code: props.match.params.code,
            loaded: false,
        };
        this.getCareer = this.getCareer.bind(this);
        this.onFieldChanged = this.onFieldChanged.bind(this);
        this.apply = this.apply.bind(this);
        this.recaptchaRef = React.createRef();
        this.grecaptcha = window.grecaptcha;
    }

    componentDidMount() {
        this.getCareer();
        setTimeout(()=>this.setState({loaded: true}), 1500);
    }

    onFieldChanged($e){
        let el = $e.target;
        if(el.value!==''){
            let applicant = this.state.applicant;
            if(el.name==='instagram' || el.name==='twitter') applicant[el.name] = `@${el.value}`;
            else if(el.type==='tel') applicant[el.name] = `+62${el.value}`;
            else if(el.type==='file') applicant[el.name] = el.files[0];
            else applicant[el.name] = el.value;
            this.setState({applicant: applicant});
        }
    }

    render() {
        return (
            <div className="container ph8 pv6 section-container">
                <div className="section-header">
                    <div className="title">Posisi Lowongan</div>
                    <div className="subtitle">{this.state.data.name}</div>
                </div>
                <div className="section-content">
                    <div className="row d-flex justify-content-between align-items-stretch">
                        <div className="">Tersedia {this.state.data.availability} Lowongan</div>
                        <div className="">Dibuka hingga <Moment format={"DD MMMM yyyy - HH:mm"} parse={moment.ISO_8601} locale={"id"}>{this.state.data.due_at}</Moment></div>
                    </div>
                    <div className={`job-description`}>
                        {parse(this.state.data.description||"")}
                    </div>
                </div>
                <div className="section-content">
                    <h3 className={`text-center mb-3`}>Lamar Posisi ini</h3>
                    <form className="job-form" method="post" encType={`multipart/form-data`} onSubmit={e=>{e.preventDefault(); this.apply()}}>
                        <div className="mb-3">
                            <label>Nama *</label>
                            <input className="form-control" type="text" name="name" placeholder="Nama Anda" onChange={$e=>this.onFieldChanged($e)} required/>
                        </div>
                        <div className="mb-3">
                            <label>Email *</label>
                            <input className="form-control" type="email" name="email" placeholder="contoh@teknoku.digital" onChange={$e=>this.onFieldChanged($e)} required/>
                        </div>
                        <div className="mb-3">
                            <label>No Telepon / HP *</label>
                            <div className="input-group">
                                <span className="input-group-text">+62</span>
                                <input className="form-control" type="tel" name="phone" placeholder="81234567890" onChange={$e=>this.onFieldChanged($e)} required />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Instagram</label>
                            <div className="input-group">
                                <span className="input-group-text">@</span>
                                <input className="form-control" type="text" name="instagram" placeholder="instagram" onChange={$e=>this.onFieldChanged($e)} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Facebook</label>
                            <input className="form-control" type="text" name="facebook" placeholder="facebook profile link" onChange={$e=>this.onFieldChanged($e)}/>
                        </div>
                        <div className="mb-3">
                            <label>Twitter</label>
                            <div className="input-group">
                                <span className="input-group-text">@</span>
                                <input className="form-control" type="text" name="twitter" placeholder="twitter" onChange={$e=>this.onFieldChanged($e)} />
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Website</label>
                            <input className="form-control" type="text" name="website" placeholder="https://website.com" onChange={$e=>this.onFieldChanged($e)}/>
                        </div>
                        <div className="mb-3">
                            <label>Alamat *</label>
                            <textarea className="form-control" name="address" rows={5} onChange={$e=>this.onFieldChanged($e)} required/>
                        </div>
                        <div className="mb-3">
                            <label>Tentang Anda *</label>
                            <div className="text-muted" style={{fontSize: 14}}>Ceritakan tentang diri Anda, bagaimana Anda menilai diri Anda, motivasi dan apa yang Anda ketahui tentang posisi ini, kelebihan dan kekurangan Anda, dsb.</div>
                            <textarea className="form-control" name="about" rows={5} onChange={$e=>this.onFieldChanged($e)} required/>
                        </div>
                        <div className="mb-3">
                            <label>CV *</label>
                            <input className="form-control form-control-sm" type="file" name="cv" accept="application/pdf" onChange={$e=>this.onFieldChanged($e)} required />
                        </div>
                        <div className="mb-3">
                            <label>Portofolio</label>
                            <input className="form-control form-control-sm" type="file" name="portfolio" accept="application/pdf" onChange={$e=>this.onFieldChanged($e)} />
                        </div>
                        <div className="text-muted">
                            * ) Harus diisi
                        </div>
                        <div className="d-flex justify-content-center mt-3 mb-3">
                            {this.state.loaded && (<ReCAPTCHA sitekey={GOOGLE_RECAPTCHA_SITE_KEY} ref={this.recaptchaRef} grecaptcha={this.grecaptcha} />)}
                        </div>
                        <div className="d-flex justify-content-center mt-5 mb-3">
                            <button type="submit" className="btn btn-sm btn-info">Ajukan Lamaran</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    getCareer(){
        axiosInstance.get(`career?code=${this.state.code}`)
            .then(res => {
                if(res.status===200 && res.data.status===1){
                    this.setState({data: res.data.data});
                }else{
                    alert('Karir tidak ditemukan')
                    this.props.history.push('/career');
                }
            });
    }

    apply(){
        if(this.recaptchaRef.current.getValue()) {
            const data = new FormData();
            data.append("career_id", this.state.data.id);
            data.append("recaptcha", this.recaptchaRef.current.getValue());
            Object.keys(this.state.applicant).forEach(k => {
                data.append(k, this.state.applicant[k]);
            });
            axiosInstance.post(`career/apply`, data).then((res) => {
                if (res.status === 200) {
                    if (res.data.status === 1) {
                        alert(`Lamaran Anda pada posisi ${this.state.data.name} berhasil diajukan, silahkan tunggu kabar selanjutnya yang akan dikirimkan melalui Email/SMS/WA. Terima Kasih`);
                        this.props.history.push('/career');
                    } else {
                        alert(res.data.status_message);
                    }
                } else {
                    alert("Gagal mengirimkan data ke server")
                }
            });
        }else{
            alert("Silahkan verifikasi captcha terlebih dahulu, sebelum melanjutkan");
        }
    }
}
