import React, {Component} from "react";
import '../styles/_careers.scss';
import Pagination from "./Pagination";
import Moment from "react-moment";
import "moment/locale/id";
import * as moment from "moment";
import {Link} from "react-router-dom";
import {axiosInstance} from "../App";

export default class Careers extends Component{
    constructor(props) {
        super(props);
        this.pageChanged = this.pageChanged.bind(this);
        this.perPageChanged = this.perPageChanged.bind(this);
        this.state = {data: [],total:0,page:0,perPage:10};
    }

    componentDidMount() {
        this.pageChanged(1);
    }

    render() {
        return (
            <div className="container ph8 pv6 careers-container">
                <div className="section-header">
                    <div className="title">Karir</div>
                    <div className="subtitle">Bergabung dengan <span className="highlight">Tim Kami</span></div>
                </div>
                <div className="row d-flex justify-content-between align-items-stretch">
                    {
                        this.state.data.map(career => (
                            <Item key={career.id} className="col-12 col-md-6" title={career.name} code={career.code} available={career.availability} disabled={career.availability===0} dueAt={career.due_at}>
                                {career.short_description}
                            </Item>
                        ))
                    }
                </div>
                <Pagination page={this.state.page} lastPage={this.state.lastPage} perPage={this.state.perPage} pageChanged={this.pageChanged} perPageChanged={this.perPageChanged} />
            </div>
        );
    }

    pageChanged(page, limit = this.state.perPage){
        axiosInstance.get(`career/paginate?page=${page}&limit=${limit}`)
            .then(res =>{
                if(res.status===200 && res.data.status===1){
                    const data = res.data.data;
                    this.setState({data: data.data,total: data.total,page:data.current_page})
                }
            });
    }

    perPageChanged(perPage){
        this.setState({perPage: perPage});
        this.pageChanged(this.state.page, perPage);
    }
}

class Item extends Component{
    render() {
        return (
            <div className={"p0 mb3 "+(this.props.className!==undefined?this.props.className:"")}>
                <div className={"card mr3"} style={{border:0, backgroundColor: "#f4f4f4", height: "100%"}}>
                    <div className="card-body d-flex flex-column justify-content-between">
                        <div className="row">
                            <div className="col-12 d-flex flex-column justify-content-between align-items-start">
                                <h4 className="mt3">{this.props.title}</h4>
                            </div>
                            <div className="col-12 d-flex justify-content-between">
                                <div className="d-flex flex-column">Tersedia <div>{this.props.available || 0} Lowongan</div></div>
                                <div className="d-flex flex-column align-items-end">Batas Akhir<Moment format={"DD MMMM yyyy - HH:mm"} parse={moment.ISO_8601} locale={"id"}>{this.props.dueAt}</Moment></div>
                            </div>
                            <p className="col-12 mt4">
                                {this.props.children}
                            </p>
                        </div>
                        <div className="row mr1">
                            <Link className={"btn btn-sm "+(this.props.disabled?"btn-outline-secondary":"btn-outline-success")} style={{marginLeft: "auto"}} disabled={this.props.disabled} to={`/career/apply/${this.props.code}`}>
                                {this.props.disabled?"Tidak tersedia":"Lamar Posisi Ini"}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
