import React from 'react';
import './App.scss';
import {BrowserRouter, Route, Switch} from "react-router-dom";
import Loader from "./components/Loader";
import Home from "./pages/Home";
import Career from "./pages/Career";
import axios from "axios";

function App() {
  return (
    <div className="App">
      <Loader />
      <BrowserRouter>
          <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/career" component={Career} />
          </Switch>
      </BrowserRouter>
    </div>
  );
}

export const axiosInstance = axios.create({
    baseURL: 'https://api.teknoku.digital/v1/',
    timeout: 20000,
    timeoutErrorMessage: "Gagal terhubung ke Server",
    headers: {"Authorization": "Basic "+btoa("117756fcb3cad534d70517596a733cb4e38be685:")},
});
export const GOOGLE_RECAPTCHA_SITE_KEY = "6LdZ2y0aAAAAAKXtlDorINHqmuB8WyuttB9NphSb";

export default App;
