import React,{Component} from "react";
import '../styles/_footer.scss';

export default class Footer extends Component{
    render() {
        return (
            <div className="d-flex justify-content-center pv2 footer">
                Copyright &copy; {new Date().getFullYear()} - PT.Teknoku Digital Indonesia
            </div>
        );
    }
}
