import React, {Component} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faChartArea, faCode,
    faCoffee,
    faGlobe,
    faMobile, faQuestionCircle, faSearch,
    faServer,
    faShoppingBasket,
} from "@fortawesome/free-solid-svg-icons";
import '../styles/_services.scss';

export default class Services extends Component{

    render() {
        return (
            <div className="container ph8 pv6 services-container">
                <div className="section-header">
                    <div className="title">Layanan Kami</div>
                    <div className="subtitle">Sesuai kebutuhan <span className="highlight">Anda</span></div>
                </div>
                <div className="row">
                    <Item className="col-12 col-md-4" title="Pembuatan Website" icon={faGlobe}>
                        Membangun situs web untuk Pribadi, Organisasi, Instansi, Perusahaan, maupun Bisnis
                    </Item>
                    <Item className="col-12 col-md-4" title="Aplikasi Android iOs" icon={faMobile}>
                        Membangun aplikasi mobile di lingkungan Smartphone terbesar di Dunia
                    </Item>
                    <Item className="col-12 col-md-4" title="E-Commerce" icon={faShoppingBasket}>
                        Membangun web e-commerce Anda sendiri untuk mendukung bisnis Anda
                    </Item>
                    <Item className="col-12 col-md-4" title="Web & Data Hosting" icon={faServer}>
                        Menyediakan layanan hosting dengan manajemen terbaik bagi Web dan Data Anda
                    </Item>
                    <Item className="col-12 col-md-4" title="Digital Marketing" icon={faChartArea}>
                        Memberikan solusi terbaik pemasaran bisnis secara digital
                    </Item>
                    <Item className="col-12 col-md-4" title="SEO" icon={faSearch}>
                        Membantu website Anda mendapatkan peringkat teratas di Search Engine (Google, etc)
                    </Item>
                    <Item className="col-12 col-md-4" title="IT Business Consultant" icon={faCoffee}>
                        Memberikan solusi Teknologi yang dapat mendukung berbagai bisnis
                    </Item>
                    <Item className="col-12 col-md-4" title="SaaS" icon={faCode}>
                        Membangun ekosistem aplikasi Anda TANPA pusing dengan In-House Development
                    </Item>
                    <Item className="col-12 col-md-4" title="Lainnya" icon={faQuestionCircle}>
                        Hubungi kami untuk mengetahui layanan yang sesuai dengan kebutuhan Anda
                    </Item>
                </div>
            </div>
        );
    }
}

class Item extends Component{

    render() {
        return (
            <div className={"p0 "+(this.props.className!==undefined?this.props.className:"")}>
                <div className={"card mr3 mb3"} style={{border:0, backgroundColor: "#f4f4f4"}}>
                    <div className="card-body">
                        <div className="row">
                            <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                                <FontAwesomeIcon icon={this.props.icon} size="4x" color="#9B9B9B" />
                                <h4 className="mt3">{this.props.title}</h4>
                            </div>
                            <p className="col-12 mt4">
                                {this.props.children}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
