import React, {Component} from "react";
import Layout from "../components/Layout";
import {Carousel} from "react-bootstrap";
import Services from "../components/Services";

export default class Home extends Component{
    constructor(props) {
        super(props);
        this.slideTo = this.slideTo.bind(this);
        this.state = {
            index: 0,
        };
    }

    slideTo(selectedIndex, e){
        this.setState({index: selectedIndex});
    }

    render() {
        return (
            <Layout activeIndex={0}>
                <Carousel activeIndex={this.state.index} onSelect={this.slideTo}>
                    <Carousel.Item>
                        <img srcSet="/assets/img/slide1.png" className="d-block w-100" alt="Teknoku Digital Indonesia" />
                    </Carousel.Item>
                    <Carousel.Item>
                        <a href="https://kiosku.id?utm_campaign=referral&utm_source=teknoku.digital&utm_medium=website-carousel" style={{cursor:"pointer"}}
                            target="_blank" rel="noopener noreferrer">
                            <img srcSet="/assets/img/slide2.png" className="d-block w-100" alt="Kiosku.ID" />
                        </a>
                    </Carousel.Item>
                    {/*<Carousel.Item>
                        <img srcSet="/assets/img/slide3.png" className="d-block w-100" alt="..." />
                    </Carousel.Item>*/}
                </Carousel>
                <Services />
            </Layout>
        );
    }
}
