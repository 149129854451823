import React,{Component} from "react";
import "../styles/_linker.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/fontawesome-free-brands";
import MapContainer from "../plugins/MapContainer";
import {faArrowRight, faEnvelope, faPhoneAlt} from "@fortawesome/free-solid-svg-icons";

export default class Linker extends Component{
    render() {
        return (
            <div className="d-flex justify-content-between pv4 mh0 row" style={{backgroundColor: "#2d2d2d", color: "white"}}>
                <div className="col-12 col-md-3 ph6">
                    <div className="linker">
                        <div className="linker-header-logo" />
                        <div className="linker-header">
                            Kantor Pusat
                        </div>
                        <ul className="linker-list">
                            <li>Teknoku Digital Indonesia PT.</li>
                            <li>Jl.Andong Sinawi No.1</li>
                            <li>Bobotsari, Purbalingga. 53353.</li>
                            <li><a href="https://g.page/teknokudigital?share" target="_blank" rel="noopener noreferrer" style={{fontSize:12, color: "#44bb87", textDecoration: "underline"}}>Kunjungi di Google Map</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-12 col-md-5 ph6">
                    <div className="row">
                        <div className="col-12">
                            <div className="linker-header">
                                Langganan
                            </div>
                            <ul className="linker-list">
                                <li>Ikuti berita terbaru dari Kami untuk mendapatkan Informasi terkini, Penawaran dan berbagai Promo menarik</li>
                            </ul>
                            <div className="input-group bg-white" style={{borderRadius: 5}}>
                                <input type="text" className="form-control border-0" placeholder="Email Address... " />
                                <button type="button" className="input-group-append btn btn-white bg-transparent text-dark border-0 d-flex align-items-center">
                                    <FontAwesomeIcon icon={faArrowRight} fixedWidth={true} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <div className="linker-header">
                                Layanan
                            </div>
                            <ul className="linker-list">
                                <li>Pembuatan Website</li>
                                <li>Aplikasi Android iOs</li>
                                <li>E-Commerce</li>
                                <li>Web & Data Hosting</li>
                                <li>Digital Marketing</li>
                                <li>IT Business Consultant</li>
                                <li>Lainnya</li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <div className="linker-header">
                                Produk
                            </div>
                            <ul className="linker-list">
                                <li><a href="https://kiosku.id?utm_campaign=referral&utm_source=teknoku.digital&utm_medium=website" target="_blank" rel="noopener noreferrer">Kiosku.ID</a></li>
                                <li><a href="https://apis.id?utm_campaign=referral&utm_source=teknoku.digital&utm_medium=website" target="_blank" rel="noopener noreferrer">APIs.ID</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="col-12 col-md-4 ph6">
                    <div className="linker-header">
                        Kontak Kami
                    </div>
                    <ul className="linker-list">
                        <li><FontAwesomeIcon icon={faPhoneAlt} fixedWidth={true}/>  <a href="tel:02817700765" target="_blank" rel="noopener noreferrer">(0281) 7700 765</a></li>
                        <li><FontAwesomeIcon icon={faWhatsapp} fixedWidth={true}/>  <a href="https://wa.me/+628170000322" target="_blank" rel="noopener noreferrer">+62 81 70.000 322</a></li>
                        <li><FontAwesomeIcon icon={faEnvelope} fixedWidth={true}/>  <a href="mailto:cs@teknoku.digital" target="_blank" rel="noopener noreferrer">cs@teknoku.digital</a></li>
                    </ul>
                    <div className="linker-header">
                        Lokasi Kami
                    </div>
                    <MapContainer />
                </div>
            </div>
        );
    }
}
