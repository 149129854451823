import React, {Component} from "react";
import {Link} from "react-router-dom";
import '../styles/_navbar.scss';

export default class Navbar extends Component{
    render() {
        return (
            <div className="site-header">
                <div className="navbar navbar-expand">
                    <nav className="container">
                        <Link className="navbar-brand" to={"/"}>
                            <img className="logo logo-dark" alt="logo" srcSet="/assets/img/logo.png 6x" />
                        </Link>
                        <div className="collapse navbar-collapse d-flex justify-content-between" id="navbarToggle">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link className={"nav-link "+ (this.props.activeIndex===0?"active":"")} aria-current="page" to={"/"}>Beranda</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={"nav-link "+ (this.props.activeIndex===1?"active":"")} to={"/career"}>Karir</Link>
                                </li>
                            </ul>
                            <ul className="navbar-nav contact">
                                <li className="nav-item" style={{backgroundColor: "#f4f4f4"}}>
                                    <a className="nav-link btn btn-sm" style={{color: "#0f7e00"}}
                                       href="https://wa.me/+628170000322?text=Hi%2cTeknoku&source=web"
                                       target="_blank" rel="noopener noreferrer">Hubungi Sekarang</a></li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        );
    }
}
