import React, {Component} from "react";
import Layout from "../components/Layout";
import Careers from "../components/Careers";
import {Route} from "react-router-dom";
import Apply from "../components/Apply";

export default class Career extends Component{
    render() {
        return (
            <Layout activeIndex={1}>
                <Route exact path="/career" component={Careers} />
                <Route path="/career/apply/:code" component={Apply} />
            </Layout>
        );
    }
}
